import React, { Key, ReactNode, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MediaPlayer } from '@hawkins-community/media-player';
import { AssetBrowserItem } from '@hawkins-community/asset-browser/v4';
import { Box, ErrorState, Loader, useCanImageLoad } from '@hawkins/components';
import { IconAudioClip, IconDocumentPdf } from '@hawkins/icons';
import { makeStyles } from '@hawkins/styles';

import { previewUrl, proxyUrl } from '../../util';
import { useAssetDetail } from '../batch/useDetailCache';
import { CounterChip } from '../CounterChip';
import { PasswordContext } from '../../context';

export interface RenderCarouselItemProps {
  itemId: Key;
  isActive: boolean;
  index: number;
  total: number;
}

const Loading = (): JSX.Element => (
  <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'100%'}>
    <Loader />
  </Box>
);

function deviceOS() {
  if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    return 'iOS';
  }

  // Use the 'bluetooth' in navigator to detect Android devices since is only available in Android devices.
  if (navigator.userAgent.match(/Android/i) || 'bluetooth' in navigator) {
    return 'android';
  }

  return;
}

function isMobile() {
  const isMobile = window.matchMedia('(max-width: 750px)').matches;
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints;

  return isMobile || isTouchDevice;
}

const useStyles = makeStyles(({ hawkinsVariables }) => ({
  browserItem: {
    zIndex: 10,
  },
}));

export const DetailPreviewItem = (props: RenderCarouselItemProps): JSX.Element | null => {
  const { shareKey } = useParams();
  const { itemId, isActive, index, total } = props;
  const password = useContext(PasswordContext);
  const classes = useStyles();
  if (!shareKey || !itemId) {
    throw new Error('cannot display details without a valid shared item');
  }
  const generatedPreviewUrl = useMemo(
    () => previewUrl(shareKey, itemId.toString(), password),
    [shareKey, itemId, password],
  );
  const { data: assetDetails, isLoading } = useAssetDetail(shareKey.toString(), itemId.toString(), password);
  const status = useCanImageLoad(generatedPreviewUrl);
  const src = useMemo((): ReactNode => {
    if (status === 'loading' && isLoading) {
      return <Loading />;
    }

    if (isActive) {
      if (assetDetails?.isVideoPlayable) {
        if (assetDetails.watermarkStatus === 'IN_PROGRESS') {
          return (
            <Box display={'flex'} justifyContent={'center'} height={'100%'} alignItems={'center'}>
              <ErrorState
                size='small'
                title='Video is being processed!'
                subtitle='Please wait a moment while we finish preparing your video for viewing.'
                variant='unavailable'
              />
            </Box>
          );
        }

        if (isMobile() && deviceOS() === 'iOS') {
          return (
            <video
              style={{ flex: 1, width: '100%' }}
              controls
              playsInline
              disablePictureInPicture
              autoPlay={true}
              muted={true}
            >
              <source src={proxyUrl(shareKey, itemId.toString(), password)} type={assetDetails?.mimeType} />
            </video>
          );
        }

        return (
          <MediaPlayer
            config={{
              excludeControls: [
                'loopPlay',
                'loopSubtitle',
                'toggleLoopPlay',
                'fnCC',
                'showSettings',
                'pictureInPicture',
                'languages',
              ],
            }}
            viewable={{
              video: {
                url: proxyUrl(shareKey, itemId.toString(), password),
                frameRateTag: 'FPS_2397',
              },
            }}
            autoPlay={true}
            watermark={{
              hide: true,
            }}
          />
        );
      }
    }

    if (assetDetails && assetDetails.mimeType) {
      if (assetDetails.mimeType.includes('audio')) {
        return (
          <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <IconAudioClip />
          </div>
        );
      }
      if (assetDetails.mimeType.includes('pdf')) {
        return (
          <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <IconDocumentPdf />
          </div>
        );
      }
    }

    return generatedPreviewUrl;
  }, [assetDetails, isActive, itemId, password, shareKey, status, generatedPreviewUrl, isLoading]);

  return (
    <AssetBrowserItem
      itemKey={itemId}
      src={src}
      alt={`${index + 1} of ${total}`}
      objectFit='contain'
      className={isMobile() ? classes.browserItem : undefined}
    >
      <CounterChip index={index} total={total} />
    </AssetBrowserItem>
  );
};
