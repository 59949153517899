import React, { useCallback, useContext, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@hawkins/components';
import { IconDownloadsSmart } from '@hawkins/icons';
import { makeStyles } from '@hawkins/styles';

import { getAssetDetailById } from '../batch/useDetailCache';
import { BatchContext, PasswordContext } from '../../context';
import { useDownloadURL } from '../../hooks';

function openTab(url: string) {
  return new Promise((resolve) =>
    setTimeout(() => {
      window.open(url);
      resolve(url);
    }, 1000),
  );
}

const useStyles = makeStyles((hawkins) => ({
  btnContainer: {
    display: 'none',
    [hawkins.hawkinsUtils.breakpoints.up('breakpointSmall')]: {
      display: 'inline-flex',
    },
  },
}));

export const DownloadAllAssets = () => {
  const batch = useContext(BatchContext);
  const password = useContext(PasswordContext);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const classNames = useStyles();
  const downloadLink = useDownloadURL();

  const downloadAllAssets = useCallback(async () => {
    if (!batch?.assetIds || loading) {
      return;
    }
    setLoading(true);
    const assets: Array<Promise<string>> = [];
    for (const assetId of batch.assetIds) {
      try {
        const data = await queryClient.fetchQuery(['assetDetail', assetId.id, password], () =>
          getAssetDetailById(batch.share.shareKey, assetId.id, password),
        );
        if (!data || (data.isVideoPlayable && data.watermarkStatus === 'IN_PROGRESS')) {
          continue;
        }
        assets.push(
          Promise.resolve(
            downloadLink({ shareKey: batch.share.shareKey, assetId: assetId.id, childAssetId: assetId.id, password }),
          ),
        );
      } catch (e) {
        console.error(e);
      }
    }

    // create a promise for each asset with a delay in between
    for (const asset of assets) {
      await openTab(await asset);
    }
    setLoading(false);
  }, [loading, batch, password, queryClient, downloadLink]);

  // Don't display if no assets or only one asset is present.
  if (!batch || !batch.assetIds || batch.assetIds.length <= 1) {
    return null;
  }

  // Don't display if the share is not downloadable.
  if (!batch.share.isDownloadable) {
    return null;
  }

  return (
    <Button
      variant={'secondary'}
      icon={IconDownloadsSmart}
      onClick={downloadAllAssets}
      executing={loading}
      classes={{ root: classNames.btnContainer }}
    >
      Download All Assets
    </Button>
  );
};
