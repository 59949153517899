import { useCanImageLoad } from '@hawkins/components';
import { AssetBrowserItem } from '@hawkins-community/asset-browser/v4';
import React from 'react';
import { IconAudioClip, IconDocumentPdf } from '@hawkins/icons';

import { GetDetailsForAssetResponse, useAssetDetail } from '../batch/useDetailCache';

import { ThumbnailError } from './ThumbnailError';
import { WatermarkInProgress } from './WatermarkInprogress';
import { MissingThumbnail } from './MissingThumbnail';

interface ThumbnailProps {
  children?: React.ReactNode;
  id: string;
  src: string;
  shareKey: string;
  password: string | null;
}

export const ErrorChildren = ({
  assetDetails,
}: {
  assetDetails: GetDetailsForAssetResponse | undefined;
}): JSX.Element | null => {
  if (assetDetails?.isVideoPlayable) {
    return assetDetails?.watermarkStatus === 'IN_PROGRESS' ? <WatermarkInProgress /> : <MissingThumbnail />;
  }

  if (assetDetails?.mimeType?.includes('audio')) {
    return <IconAudioClip />;
  }

  if (assetDetails?.mimeType?.includes('pdf')) {
    return <IconDocumentPdf />;
  }

  return null;
};

export const Thumbnail = ({ children, id, src, shareKey, password }: ThumbnailProps): JSX.Element => {
  const status = useCanImageLoad(src);
  const { data: assetDetails } = useAssetDetail(shareKey, id, password);

  return (
    <AssetBrowserItem
      itemKey={id}
      src={
        status === 'loaded' ? (
          src
        ) : (
          <ThumbnailError>
            <ErrorChildren assetDetails={assetDetails} />
          </ThumbnailError>
        )
      }
      alt={''}
    >
      {children}
    </AssetBrowserItem>
  );
};
