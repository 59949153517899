import React, { Key, useContext, useMemo, useState } from 'react';
import { Box } from '@hawkins/components';
import { spacings } from '@hawkins/variables';
import { ABContext, AssetBrowserContext, VirtualizedItem } from '@hawkins-community/asset-browser/v4';
import { useParams } from 'react-router-dom';

import { useScreenContext } from '../../hooks';
import { BatchContext } from '../../context';

import { GridItem } from './GridItem';

const basis = { flexBasis: 'min-content' };

export const Grid = (): JSX.Element => {
  const batch = useContext(BatchContext);
  const { isHeightSmall, isWidthSmall } = useScreenContext();
  const [highlight, setHighlight] = useState<Key | undefined>(undefined);
  const { focusKey } = useParams();

  const contextValue = useMemo<ABContext>(() => {
    return {
      fixedItemHeight: isWidthSmall || isHeightSmall ? 180 : 225,
      fixedItemWidth: isWidthSmall || isHeightSmall ? 320 : undefined,
      onItemClick: (key) => setHighlight(highlight === key ? undefined : key),
      highlight: focusKey ? undefined : highlight,
    };
  }, [focusKey, highlight, isHeightSmall, isWidthSmall]);

  if (!batch?.share) {
    throw new Error('cannot be rendered without data');
  }
  return (
    <Box padding={spacings.space5} gap={spacings.space2} display='flex' flexDirection='row' flexWrap='wrap'>
      <AssetBrowserContext.Provider value={contextValue}>
        {batch.assetIds.map((item, index) => (
          <VirtualizedItem key={item.id} initialWidth={300} initialHeight={300} style={basis}>
            <GridItem itemId={item.id} index={index} total={batch.assetIds.length} />
          </VirtualizedItem>
        ))}
      </AssetBrowserContext.Provider>
    </Box>
  );
};
