import {
  Modal,
  AppLogo,
  Button,
  Heading,
  ModalBody,
  ModalHeader,
  Loader,
  MenuItem,
  MenuGroup,
} from '@hawkins/components';
import { IconX, IconDownload } from '@hawkins/icons';
import { spacings } from '@hawkins/variables';
import React, { useCallback, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { BatchContext, PasswordContext } from '../../context';
import { PublicAssetFile, useAssetDetail } from '../batch/useDetailCache';
import { prepFileNameForWrap } from '../Grid/GridItem';
import { useDownloadURL } from '../../hooks';

export const DownloadModal = (): JSX.Element | null => {
  const { shareKey, focusKey, action } = useParams();
  const navigate = useNavigate();
  const password = useContext(PasswordContext);
  const { data: assetDetails, isLoading } = useAssetDetail(shareKey || null, focusKey || null, password);
  const batch = useContext(BatchContext);
  const createDownloadLink = useDownloadURL();

  const gotoShare = useCallback(() => {
    navigate(`/${shareKey}`);
  }, [navigate, shareKey]);
  const startDownload = useCallback(
    (downloadableAsset: PublicAssetFile) => {
      window.open(
        createDownloadLink({
          shareKey: shareKey || '',
          assetId: focusKey,
          childAssetId: downloadableAsset.assetId.id,
          password: password,
        }),
      );
    },
    [createDownloadLink, shareKey, password, focusKey],
  );
  if (action !== 'download' || !shareKey || !focusKey) {
    return null;
  }
  if (!batch?.share) {
    throw new Error('cannot be rendered without data');
  }
  const fileData = groupByExtension(assetDetails?.downloadableAssets);
  const fileTypes = Object.keys(fileData);

  return (
    <Modal disableAnimation open onClose={gotoShare}>
      <ModalHeader layout={{ display: 'flex', justifyContent: 'space-between' }}>
        <AppLogo name='Asset Share' studioLogo />
        <Button variant='icon' icon={IconX} component={Link} to={`/${shareKey}`}>
          Close
        </Button>
      </ModalHeader>
      <ModalBody>
        <Heading level={2}>Downloadable files</Heading>
        {isLoading ? <Loader variant='circular' /> : null}
        {assetDetails === null && <Loader style={{ alignSelf: 'center', margin: spacings.space6 }} />}
        {fileTypes.length > 0
          ? fileTypes.map((fileType) => (
              <MenuGroup label={`.${fileType.toUpperCase()} Files`}>
                {fileData[fileType].map((downloadableAsset) => (
                  <MenuItem
                    rootProps={{ download: true }}
                    onClick={() => startDownload(downloadableAsset)}
                    icon={IconDownload}
                  >
                    {prepFileNameForWrap(downloadableAsset.fileName)}
                  </MenuItem>
                ))}
              </MenuGroup>
            ))
          : null}
      </ModalBody>
    </Modal>
  );
};

const groupByExtension = (files?: PublicAssetFile[]): Record<string, PublicAssetFile[]> =>
  (files || []).reduce((acc, item) => {
    const ext = item.fileName.split('.').pop() || 'unknown';
    if (!acc[ext]) {
      acc[ext] = [];
    }
    acc[ext].push(item);
    return acc;
  }, {} as Record<string, PublicAssetFile[]>);
