export type SerializedAssetId = string;

export const thumbnailUrl = (shareKey: string, itemId: string, password: string | null) =>
  `/share/${shareKey}/${itemId}/thumbnail${password ? `?password=${encodeURIComponent(password)}` : ''}`;

export const proxyUrl = (shareKey: string, itemId: string, password: string | null) =>
  `/share/${shareKey}/${itemId}/proxy${password ? `?password=${encodeURIComponent(password)}` : ''}`;

export const previewUrl = (shareKey: string, itemId: string, password: string | null) =>
  `/share/${shareKey}/${itemId}/preview${password ? `?password=${encodeURIComponent(password)}` : ''}`;

export const formattedAppName = (app: string): string => {
  switch (app) {
    case `VANDAM`:
      return `VanDAM`;
    case `PAL`:
      return `PAL`;
    default:
      return app;
  }
};
