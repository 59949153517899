import React, { Key, useCallback, useContext, useMemo, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AppLogo, Button, HorizontalNavigation, Modal } from '@hawkins/components';
import { IconX } from '@hawkins/icons';
import { colors } from '@hawkins/variables';
import { ABContext, AssetBrowserContext, Carousel } from '@hawkins-community/asset-browser/v4';
import { makeStyles } from '@hawkins/styles';

import { useScreenContext } from '../../hooks';
import { BatchContext, PasswordContext } from '../../context';
import { Thumbnail } from '../Thumbnail';
import { CounterChip } from '../CounterChip';
import { thumbnailUrl } from '../../util';

import { DetailPreviewItem } from './DetailPreviewItem';

const useStyles = makeStyles({
  modalContainer: {
    padding: 0,
  },
  scrollGallery: {
    height: 140,
    backgroundColor: colors.whiteT20,
    display: 'grid',
    gridAutoFlow: 'column',
    overflowX: 'scroll',
  },
});

export const DetailModal = (): JSX.Element | null => {
  const { shareKey, focusKey, action } = useParams();
  const navigate = useNavigate();
  const { isHeightSmall } = useScreenContext();
  const ribbonRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const password = useContext(PasswordContext);
  const batch = useContext(BatchContext);
  const adjustRibbonVisible = useCallback((focussedItemId: Key) => {
    setTimeout(() => {
      if (ribbonRef.current && focussedItemId) {
        const matched = ribbonRef.current.querySelector(`[data-itemid='${focussedItemId}']`);
        if (matched) {
          matched.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
      }
    }, 100);
  }, []);
  const assetIds = useMemo(() => {
    return (batch?.assetIds || []).map((item) => item.id);
  }, [batch]);
  const gotoShare = useCallback(() => {
    navigate(`/${shareKey}`);
  }, [navigate, shareKey]);
  const onItemClick = useCallback(
    (itemId: Key) => {
      navigate(`/${shareKey}/${itemId.toString()}${action ? `/${action}` : ''}`);
      adjustRibbonVisible(itemId);
    },
    [action, adjustRibbonVisible, navigate, shareKey],
  );

  const carouselContext = useMemo<ABContext>(() => ({}), []);

  if (action || !shareKey || !focusKey) {
    return null;
  }
  if (!batch || !batch.share) {
    throw new Error('cannot be rendered without data');
  }
  return (
    <Modal
      disableAnimation
      open
      onClose={gotoShare}
      fullScreen
      classes={{
        contentFullScreen: classes.modalContainer,
      }}
    >
      <HorizontalNavigation
        transparent={isHeightSmall ? true : undefined}
        style={isHeightSmall ? { position: 'absolute', width: '100%' } : undefined}
        logo={<AppLogo name='Asset Share' studioLogo />}
        userControls={
          <Button variant='icon' icon={IconX} component={Link} to={`/${shareKey}`}>
            Close
          </Button>
        }
      />
      <AssetBrowserContext.Provider value={carouselContext}>
        <Carousel
          renderer={DetailPreviewItem}
          loading={false}
          itemIds={assetIds}
          focusKey={focusKey}
          setFocus={onItemClick}
        />
      </AssetBrowserContext.Provider>
      <div className={classes.scrollGallery} ref={ribbonRef}>
        <AssetBrowserContext.Provider value={{ onItemClick, fixedItemHeight: 120, highlight: focusKey }}>
          {batch.assetIds.map((item, index) => (
            <Thumbnail
              key={item.id}
              id={item.id}
              src={thumbnailUrl(shareKey, item.id, password)}
              password={password}
              shareKey={shareKey}
            >
              <CounterChip index={index} total={batch.assetIds.length} />
            </Thumbnail>
          ))}
        </AssetBrowserContext.Provider>
      </div>
    </Modal>
  );
};
